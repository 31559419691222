import { graphql } from 'gatsby';
import * as React from 'react';

import defaultFeature from '../images/default_blog_feature_img.jpg';
import Layout from '../components/Layout/Layout';
import Typography from '../components/Typography/Typography';
import * as classes from './blogDetails.module.scss';

const Post = ({ data }) => {
  const postData = data.prismicPosts.data;
  return (
    <Layout>
      <div className={classes.root}>
        <Typography variant="h1" align="center">
          {postData.title.text}
        </Typography>

        <img
          className={classes.feature_img}
          src={postData.feature_img?.url ?? defaultFeature}
          alt={postData.feature_img?.alt ?? 'Feature image'}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: postData.blog_content.html,
          }}
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query BlogPostQuery($id: String) {
    prismicPosts(id: { eq: $id }) {
      data {
        title {
          text
        }
        feature_img {
          alt
          url
        }
        blog_content {
          html
        }
      }
    }
  }
`;
export default Post;
